/**
 * buttonType can be "primary"(default), "secondary", "tertiary", "outline" or "ghost"
 * 
 * buttonSize can be "extraSmall", "small", "regular"(default) or "large"
 * 
 * buttonText is a string
 * 
 * prefixComponent and suffixComponent - components/icons/images to add
 * 
 * isLoading, disabled - boolean (default false)
 * 
 * widthClass - tailwind class to specify width (default: w-min)
 * 
 * onClick - function to be called when clicked
 * 
 * className - any other classes to add to the button element
 * 
 * showAsterisk - true/false - will be shown only in enabled state
 */
const Button = ({
    buttonText = "",
    buttonType = "primary",
    buttonSize = "regular",
    prefixComponent,
    suffixComponent,
    isLoading = false,
    widthClass,
    onClick,
    disabled = false,
    className = "",
    showAsterisk = false,
    ...property }) => {

    const buttonClasses = () => {
        if (disabled) {
            if (['ghost', 'link'].includes(buttonType?.toLowerCase())){
                return 'text-gray-500';
            }
            else if (['special'].includes(buttonType?.toLowerCase())){
                return 'opacity-40 border-1 border-gray-300/80  bg-primary-30/20 text-basicWhite';
            }
            else {
                return 'border-1 border-transparent bg-gray-200 text-gray-400';
            }
        }
        switch (buttonType?.toLowerCase()) {
            case "special": return 'border-1 border-gray-300/80  bg-primary-30/20 text-basicWhite';
            case "ghost": return 'bg-transparent text-primary-500';
            case "link": return 'bg-transparent text-primary-500 underline';
            case "outline": return 'border-1 bg-transparent text-primary-500 border-primary-500';
            case "tertiary": return 'border-1 bg-primary-10 text-primary-500 border-transparent';
            case "secondary": return 'border-1 bg-primary-30 text-primary-500 border-transparent';
            case "reinvest": return 'text-basicBlack bg-golden-horizontal-light';
            case "primary":
            default: return 'border-1 bg-primary-500 text-basicWhite border-transparent';
        }
    }

    const hoverStateClasses = () => {
        if (disabled || isLoading) return '';
        switch (buttonType?.toLowerCase()) {
            case "ghost":
            case "link": return "hover:text-primary-300";
            case "special": return 'hover:bg-primary-30/0 hover:border-gray-300/80 hover:shadow-md';
            case "outline": return 'hover:bg-white hover:shadow-lg';
            case "tertiary": return 'hover:bg-primary-30 hover:shadow-md';
            case "secondary": return 'hover:bg-primary-10 hover:shadow-md';
            case "primary":
            default: return 'hover:bg-primary-300 hover:shadow-md';
        }
    }

    const pressedStateClasses = () => {
        if (disabled || isLoading) return '';
        switch (buttonType?.toLowerCase()) {
            case "ghost":
            case "link": return "";
            case "special": return 'active:bg-primary-600 active:border-gray-300';
            case "tertiary": return 'active:bg-primary-50';
            case "outline":
            case "secondary":
            case "primary":
            default: return 'active:bg-primary-600 active:text-basicWhite';
        }
    }

    const paddingClasses = () => {
        if (['ghost', 'link'].includes(buttonType?.toLowerCase())) return "";
        switch (buttonSize?.toLowerCase()) {
            case "extrasmall":
            case "small": return 'px-4 py-2';
            case "large": return 'px-6 py-[14px]';
            case "regular":
            default: return 'px-5 py-3';
        }
    }

    const spacingClass = () => {
        switch (buttonSize?.toLowerCase()) {
            case "extrasmall":
            case "small": return 'gap-x-1';
            case "regular":
            case "large":
            default: return 'gap-x-2';
        }
    }

    const borderRadiusClass = () => {
        if (['ghost', 'link'].includes(buttonType?.toLowerCase())) return "";
        switch (buttonSize?.toLowerCase()) {
            case "extrasmall": return 'rounded-[6px]';
            case "small":
            case "large":
            case "regular":
            default: return 'rounded-lg';
        }
    }

    const typographyClass = () => {
        switch (buttonSize?.toLowerCase()) {
            case "extrasmall": return 'p5-medium';
            case "small":
            case "large":
            case "regular":
            default: return 'p4-medium';
        }
    }

    const loaderClass = () => {
        switch (buttonType?.toLowerCase()) {
            case "ghost":
            case "link":
            case "outline":
            case "tertiary": return 'button-loader-primary';
            case "secondary":
            case "primary":
            default: return 'button-loader-white';
        }
    }

    const buttonClicked = (e) => {
        if (!disabled && !isLoading && onClick) onClick(e);
    }

    return (
        <button
            onClick={buttonClicked}
            disabled={disabled || isLoading}
            className={`flex flex-row justify-center items-center whitespace-nowrap ${widthClass ? widthClass : 'w-min'} ${!disabled && !isLoading ? 'cursor-pointer' : 'cursor-default'} ${borderRadiusClass()} ${widthClass} ${paddingClasses()} ${spacingClass()} ${buttonClasses()} ${hoverStateClasses()} ${pressedStateClasses()} ${className}`}
            {...property}
        >

            {prefixComponent && (
                <div className="w-4 h-4 object-contain flex justify-center items-center">
                    {prefixComponent}
                </div>
            )}

            <p className={typographyClass()}>{buttonText}{showAsterisk && !disabled && <span className={`text-semantic-error-base ${typographyClass()}`} > *</span>}</p>

            {isLoading ?
                (<div className={loaderClass()} />)
                :
                suffixComponent && (
                    <div className="w-4 h-4 object-contain flex justify-center items-center">
                        {suffixComponent}
                    </div>
                )}
        </button>
    );
}

export default Button;